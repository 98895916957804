import { fromSeconds, fromHours } from '@resn/gozer-misc';
import { isMobile } from '@resn/gozer-env';

export const SIZES = {
    MOBILE_THRESHOLD: 850,
    // MOBILE_THRESHOLD: 750,
};

export const LAYERS = {
    BACKGROUND: 0,
    SCENE: 1,
    UI: 2,
};

export const LINKS = {
    OXEFIT_EXPERIENCE: 'https://experience.oxefit.com/',
};

export const DIR = {
    IMG: `${__CDN__}static/images/`,
    VIDEO: `${__CDN__}static/video/`,
};

export const COLORS = {
    YELLOW: '#E0E800',
    PINK: '#ff069a',
    DARK_BLUE: '#0A1221',
    DARK_BLUE_20: '#0a122140',
    BLUE: '#0A1A3B',
    LIGHT_BLUE: '#3265fa',
    LIGHT_BLUE_2: '#82D4FF',
    HIGHLIGHT_BLUE: '#82D4FF',
    ELECTRIC_BLUE: '#3265fa',
    VIOLET: '#87189D',
    ORANGE: '#d93600',
    TRANSPARENT: 'transparent',

    WHITE: '#ffffff',
    OFF_WHITE: '#ECECEA',

    // new colors
    AQUA: '#47D7AC',
    CITRON: '#feff76',
};

export const TIME = {
    COUNTDOWN_START: fromHours(24),
    COUNTDOWN_2_HOURS: fromHours(2),
    COUNTDOWN_FINAL: fromSeconds(60),
    SHOP_LIVE: fromSeconds(197 + 6),
    QA_START: fromSeconds(206 + 6),
    QA_END: fromSeconds(547 + 6),
    END_SEQ: fromSeconds(453 + 6),
    EVENT_DUR: fromSeconds(547 + 6),
};

export const MENU_TIMESCALE = isMobile ? 1.3 : 1.1;
export const INTERACTION_THROTTLE = 5000;
export const EMAIL_REGEX = () =>
    RegExp(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

// // At least 8 characters, min 1 Uppercase 1 Lowercase 1 Number 1 special character and only contains symbols from the alphabet, num
// // export const PASSWORD_REGEX = () =>
// //     RegExp(/(?=[A-Za-z0-9@#$%^&+_*,.!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+_*,.!=])(?=.{8,}).*$/g);

// export const PASSWORD_REGEX = () =>
//     RegExp(/(?=[A-Za-z0-9@#$%^&£+_*,.!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&£+_*,.!=])(?=.{8,}).*$/g);
